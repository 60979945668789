/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { validate } from "react-email-validator";
import { getAxios, setAccessToken } from "utils";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/login-bg.jpg";

function Basic() {
  const navigate = useNavigate();

  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmitForm = async(e) => {
    e.preventDefault();

    if(!email || !senha) {
      setErro('Insira um email e uma senha!');
      return;
    }

    if(!validate(email) || senha.length < 5) {
      setErro('Insira um email e uma senha válidos!');
      return;
    }

    const data = new FormData();

    const axiosInstance = getAxios();
    const uri = '/proxy/loginAuthority';
    let response;

    data.append('email', email);
    data.append('password', senha);

    try {
      response = await axiosInstance.post(uri, data);
    } catch(err) {
      if(err.response) {
        setErro('Email ou senha inválidos');
        return;
      }
    }

    if(!response.data) {
      setErro('Erro interno');
      return;
    }

    setAccessToken(response.data.result.newAuthToken);

    setErro(undefined);
    navigate('/dashboard');
  }

  const handleCloseAlert = () => {
    setErro(undefined);
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const session = urlParams.get('session');
    
    if(session === "expired") {
      setErro("Sua sessão expirou");
    } else {
      setErro(undefined);
    }
  }, [])

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmitForm}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth onChange={(e) => setEmail(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth onChange={(e) => setSenha(e.target.value)}/>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            {!!erro && <MDAlert color="warning" dismissible onClick={handleCloseAlert}>{erro}</MDAlert>}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
